/**
 * @type {number}
 */
const NUM_MILLISECONDS_PER_SECOND = 1000;
const NUM_SECONDS_PER_MINUTE = 60;
const NUM_SECONDS_PER_HOUR = NUM_SECONDS_PER_MINUTE * 60;
const NUM_SECONDS_PER_DAY = NUM_SECONDS_PER_HOUR * 24;
const NUM_SECONDS_PER_WEEK = NUM_SECONDS_PER_DAY * 7;
const NUM_SECONDS_PER_MONTH = NUM_SECONDS_PER_DAY * 30;
const NUM_SECONDS_PER_YEAR = NUM_SECONDS_PER_DAY * 365;
const SUNDAY_DAY_OF_WEEK = 7;
const MAX_MONTH_DISPLAY_LENGTH = 3;

export const DAYS_IN_WEEK = 7;

export interface TimeIntervals {
  intervalInYears: number;
  intervalInMonths: number;
  intervalInWeeks: number;
  intervalInDays: number;
  intervalInHours: number;
  intervalInMinutes: number;
}
export interface TimeDifferenceFromNow extends TimeIntervals {
  differenceInSeconds: number;
}

export const getReadableMonths = (): string[] => {
  return [
    window.i18next.t('pmwjs_calendar_january'),
    window.i18next.t('pmwjs_calendar_february'),
    window.i18next.t('pmwjs_calendar_march'),
    window.i18next.t('pmwjs_calendar_april'),
    window.i18next.t('pmwjs_calendar_may'),
    window.i18next.t('pmwjs_calendar_june'),
    window.i18next.t('pmwjs_calendar_july'),
    window.i18next.t('pmwjs_calendar_august'),
    window.i18next.t('pmwjs_calendar_september'),
    window.i18next.t('pmwjs_calendar_october'),
    window.i18next.t('pmwjs_calendar_november'),
    window.i18next.t('pmwjs_calendar_december'),
  ];
};

export const getReadableWeekdays = (): string[] => {
  return [
    window.i18next.t('pmwjs_calendar_sunday'),
    window.i18next.t('pmwjs_calendar_monday'),
    window.i18next.t('pmwjs_calendar_tuesday'),
    window.i18next.t('pmwjs_calendar_wednesday'),
    window.i18next.t('pmwjs_calendar_thursday'),
    window.i18next.t('pmwjs_calendar_friday'),
    window.i18next.t('pmwjs_calendar_saturday'),
  ];
};

export const getReadableTimePeriodLabels = (): {am: string; pm: string} => {
  return {
    am: window.i18next.t('pmwjs_am'),
    pm: window.i18next.t('pmwjs_pm'),
  };
};

/**
 * Converts time in int seconds to [hh:]mm:ss
 */
export const numberToMMSSFormat = (duration: number): string => {
  const durationInSeconds = duration;
  const hourPart = Math.floor(durationInSeconds / 3600);
  const minutePart = Math.floor((durationInSeconds - hourPart * 3600) / 60);
  const secondPart = Math.floor(durationInSeconds - hourPart * 3600 - minutePart * 60);
  let durationString = '';

  if (hourPart) {
    durationString += `${`00${hourPart.toString(10)}`.slice(-2)}:`;
  }
  durationString += `${`00${minutePart.toString(10)}`.slice(-2)}:${`00${secondPart.toString(10)}`.slice(-2)}`;

  return durationString;
};

export const getDayMonthYearFormat = (date: Date): string => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  return `${year}/${month}/${day}`;
};

export const formatDateFromTimestamp = (timestamp: number): string => {
  const date = getDateFromUnixTimestamp(timestamp);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear() % 100;

  const formattedDay = String(day).padStart(2, '0');
  const formattedMonth = String(month).padStart(2, '0');
  const formattedYear = String(year).padStart(2, '0');

  return `${formattedDay}/${formattedMonth}/${formattedYear}`;
};

export const getDateMonthDayFormat = (date: Date): string => {
  const monthName = getReadableMonthNameForDate(date);
  const dateNumber = date.getDate();
  const day = getReadableWeekdayForDate(date);

  return `${dateNumber} ${monthName}, ${day}`;
};

// example: 23 May
export const getDateMonthFormat = (date: Date): string => {
  const monthName = getReadableMonth(date);
  const dateNumber = date.getDate();

  return `${dateNumber} ${monthName}`;
};

export const getUnixTimestamp = (date: Date): number => {
  return Math.floor(date.getTime() / 1000);
};

export function formatLocalDate(timestamp: number): string {
  const date = getDateFromUnixTimestamp(timestamp);
  const localTime = date.getTime();

  const options = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  } as const;

  return new Intl.DateTimeFormat('en-US', options).format(localTime);
}

export function formatShortUSDate(date: Date | string | number): string {
  const options: Intl.DateTimeFormatOptions = {month: 'short', day: 'numeric', year: 'numeric'};
  return new Date(date).toLocaleDateString('en-US', options);
}

export function formatShortDateWithMonthAndDayOnly(date: Date | string | number): string {
  const options: Intl.DateTimeFormatOptions = {day: 'numeric', month: 'short'};
  return new Date(date).toLocaleDateString('en-US', options);
}

export function formatDateYYYYMMDD(date: Date | string | number): string {
  const options: Intl.DateTimeFormatOptions = {year: 'numeric', month: '2-digit', day: '2-digit'};
  return new Date(date).toLocaleDateString('en-US', options).replace(/\//g, ''); // Remove slashes
}

export const getMonthYearFormatForUTCDate = (date: Date = new Date()): string => {
  const monthNames = getReadableMonths();
  return `${monthNames[date.getUTCMonth()]} ${date.getUTCFullYear()}`;
};

export const getMonthYearFormatForDate = (date: Date = new Date()): string => {
  const monthNames = getReadableMonths();
  return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
};

/**
 * Day, Date Month like "Tuesday, 23 May"
 * @param date
 */
export const getReadableDayDateMonthFormatForDate = (date: Date = new Date()): string => {
  const month = getReadableMonthNameForDate(date);
  const weekday = getReadableWeekdayForDate(date);
  const day = date.toLocaleDateString('en-US', {day: '2-digit'});
  return `${weekday}, ${day} ${month}`;
};

export const getReadableTimeForDate = (date: Date = new Date()): string => {
  const hours: number = date.getHours();
  const minutes: number = date.getMinutes();
  const amPmLabels: {am: string; pm: string} = getReadableTimePeriodLabels();

  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const amPm = hours >= 12 ? amPmLabels.pm : amPmLabels.am;

  return `${formattedHours}:${formattedMinutes}${amPm.toLowerCase().replace(' ', '')}`;
};

export const getDateFromUnixTimestamp = (timestamp: number): Date => {
  return new Date(timestamp * 1000);
};

export const getLocalDate = (date: Date): Date => {
  if (typeof window.PMW.convertUTCDateToLocalDate === 'undefined') {
    const SECONDS_IN_MINUTE = 60;
    const MILLISECONDS_IN_SECOND = 1000;
    return new Date(date.getTime() - date.getTimezoneOffset() * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND);
  }

  return window.PMW.convertUTCDateToLocalDate(date) as Date;
};

export const getDatesBetween = (startDate: Date, endDate: Date): Date[] => {
  const dates = [];
  const currentDate = startDate;
  while (currentDate < endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);

    if (currentDate.getDate() === endDate.getDate() && currentDate.getMonth() === endDate.getMonth()) {
      break;
    }
  }

  dates.push(endDate);
  return dates;
};

export const getNextMonth = (now = new Date()): Date => {
  let next;

  if (now.getMonth() === 11) {
    next = new Date(now.getFullYear() + 1, 0, 1);
  } else {
    next = new Date(now.getFullYear(), now.getMonth() + 1, 1);
  }

  return next;
};

export const getFirstDayOfMonth = (date = new Date()): Date => {
  const y = date.getFullYear();
  const m = date.getMonth();
  return new Date(y, m, 1);
};

export const getPreviousSunday = (date = new Date()): Date => {
  date.setDate(date.getDate() - (date.getDay() || SUNDAY_DAY_OF_WEEK));
  return date;
};

export const getReadableShortMonthForIndex = (monthIndex: number): string => {
  const months = getReadableMonths();
  return months[monthIndex]?.substring(0, MAX_MONTH_DISPLAY_LENGTH) ?? '';
};

export const getReadableMonthForIndex = (monthIndex: number): string => {
  return getReadableMonths()[monthIndex];
};

export const getReadableMonthNameForDate = (date: Date): string => {
  return getReadableMonthForIndex(date.getMonth());
};

export const getReadableMonth = (date: Date): string => {
  return getReadableShortMonthForIndex(date.getMonth());
};

export const getReadableWeekdayForDate = (date: Date): string => {
  return getReadableWeekdays()[date.getDay()];
};

export const isToday = (date: Date): boolean => {
  const today = new Date();

  return today.toDateString() === date.toDateString();
};

export const isUpcomingDate = (date: Date): boolean => {
  const today = new Date();

  today.setHours(0, 0, 0, 0);
  date.setHours(0, 0, 0, 0);

  return date.getTime() >= today.getTime();
};

export const isCurrentMonth = (date: Date): boolean => {
  const today = new Date();

  return today.getMonth() === date.getMonth();
};

export const isFirstOfMonth = (date: Date): boolean => {
  return date.getDate() === 1;
};

export const getDateAndMonthStringWithOrdinal = (date: Date): string => {
  const nth = (d: number): string => {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  return `${date.getDate().toString() + nth(date.getDate())} ${getReadableMonths()[date.getMonth()]}`;
};

export const getDateStringWithOrdinal = (date: Date): string => {
  const nth = (d: number): string => {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  return `${date.getDate().toString() + nth(date.getDate())}`;
};

export const getPreviousMonth = (now = new Date()): Date => {
  let prev;
  if (now.getMonth() === 0) {
    prev = new Date(now.getFullYear() - 1, 11, 1);
  } else {
    prev = new Date(now.getFullYear(), now.getMonth() - 1, 1);
  }

  return prev;
};

export const getCurrentUTCMonthIndex = (): number => {
  return new Date().getUTCMonth();
};

export const getPreviousUTCMonthIndex = (): number => {
  return getPreviousMonthIndex(getCurrentUTCMonthIndex());
};

export const getNextUTCMonthIndex = (): number => {
  return getNextMonthIndex(getCurrentUTCMonthIndex());
};

export const getPreviousMonthIndex = (currentMonthIndex: number): number => {
  return currentMonthIndex === 0 ? 11 : currentMonthIndex - 1;
};

export const getNextMonthIndex = (currentMonthIndex: number): number => {
  return currentMonthIndex === 11 ? 0 : currentMonthIndex + 1;
};

export const getShortMonthNameForCurrentUTCMonth = (): string => {
  const currentMonthIndex = new Date().getUTCMonth();
  return getReadableShortMonthForIndex(currentMonthIndex);
};

export const getShortMonthNameForPreviousUTCMonth = (): string => {
  const currentMonthIndex = new Date().getUTCMonth();
  return getReadableShortMonthForIndex(getPreviousMonthIndex(currentMonthIndex));
};

export const getReadableMonthNameForIndex = (monthIndex: number): string => {
  return getReadableMonths()[monthIndex];
};

export const getPreviousUTCMonthName = (): string => {
  return getReadableMonthNameForIndex(getPreviousUTCMonthIndex());
};
export const getCurrentUTCMonthName = (): string => {
  return getReadableMonthNameForIndex(getCurrentUTCMonthIndex());
};
export const getNextUTCMonthName = (): string => {
  return getReadableMonthNameForIndex(getNextUTCMonthIndex());
};

export const isPastDateByAtleastOneHour = (date: Date): boolean => {
  return new Date().getTime() - date.getTime() >= NUM_SECONDS_PER_HOUR * NUM_MILLISECONDS_PER_SECOND;
};

export const isInThirtyMinutesOrLessFromNow = (date: Date): boolean => {
  const timeDifference = date.getTime() - new Date().getTime();
  return timeDifference >= 0 && timeDifference <= 30 * NUM_SECONDS_PER_MINUTE * NUM_MILLISECONDS_PER_SECOND;
};

export const hasDatedPassed = (date: Date): boolean => {
  return date.getTime() <= new Date().getTime();
};

export const hasDatePassed2 = (date: Date): boolean => {
  const currDate = new Date();

  // compare year
  if (date.getFullYear() < currDate.getUTCFullYear()) {
    return true;
  }
  if (date.getFullYear() > currDate.getUTCFullYear()) {
    return false;
  }

  // compare month
  if (date.getMonth() < currDate.getUTCMonth()) {
    return true;
  }
  if (date.getMonth() > currDate.getUTCMonth()) {
    return false;
  }

  // compare date
  if (date.getDate() < currDate.getUTCDate()) {
    return true;
  }
  if (date.getDate() > currDate.getUTCDate()) {
    return false;
  }
  return hasTimePassed(date);
};

export const hasTimePassed = (date: Date): boolean => {
  const currDate = new Date();

  // compare hours
  if (date.getHours() < currDate.getUTCHours()) {
    return true;
  }
  if (date.getHours() > currDate.getUTCHours()) {
    return false;
  }

  // compare minutes
  if (date.getMinutes() < currDate.getUTCMinutes()) {
    return true;
  }
  if (date.getMinutes() > currDate.getUTCMinutes()) {
    return false;
  }

  // compare seconds
  if (date.getSeconds() < currDate.getUTCSeconds()) {
    return true;
  }
  if (date.getSeconds() > currDate.getUTCSeconds()) {
    return false;
  }

  // compare ms
  if (date.getMilliseconds() < currDate.getUTCMilliseconds()) {
    return true;
  }
  if (date.getMilliseconds() > currDate.getUTCMilliseconds()) {
    return false;
  }
  return false;
};

export const isFutureDate = (date: Date): boolean => {
  return date.getTime() > new Date().getTime();
};

export const isBetweenDates = (dateToCheck: Date, startDate: Date, endDate: Date): boolean => {
  return dateToCheck.getTime() >= startDate.getTime() && dateToCheck.getTime() < endDate.getTime();
};

export const areDatesEqual = (date1: Date, date2: Date): boolean => {
  return date1.toDateString() === date2.toDateString();
};

export const isDateInList = (dateToCheck: Date, dates: Date[]): boolean => {
  return dates.some((date) => {
    return areDatesEqual(date, dateToCheck);
  });
};

/**
 * @param utcTimeDiff in format like "+05:00"
 */
export const getTimeZoneOffsetFromUTCOffsetInMinutes = (utcTimeDiff: string): number => {
  const regex = /([+-]?\d{1,2}):?(\d{2})?/;
  const match = utcTimeDiff.match(regex);

  if (!match) {
    return 0;
  }
  const hours = parseInt(match[1], 10);
  const minutes = match[2] ? parseInt(match[2], 10) : 0;
  const minsDiff = hours * 60 + minutes;
  return utcTimeDiff.startsWith('-') ? -minsDiff : minsDiff;
};

export const convertTimeFromTimezoneToUTC = (dateTime: Date, utcTimeDiff: string): Date => {
  const offsetInMilliseconds = getTimeZoneOffsetFromUTCOffsetInMinutes(utcTimeDiff) * 60 * 1000;
  const utcTime = dateTime.getTime() - offsetInMilliseconds;
  return new Date(utcTime);
};

export const convertUTCTimeToGivenTimezone = (dateTime: Date, utcTimeDiff: string): Date => {
  const offsetInMilliseconds = getTimeZoneOffsetFromUTCOffsetInMinutes(utcTimeDiff) * 60 * 1000;
  const utcTime = dateTime.getTime() + offsetInMilliseconds;
  return new Date(utcTime);
};

export const getTimeDifferenceFromNowInMilliseconds = (referenceDateTime: Date, referenceDateTimezoneOffset: number): number => {
  const currentDate = new Date();
  const currentTimezoneOffset = currentDate.getTimezoneOffset();
  const referenceTimezoneOffset = -referenceDateTimezoneOffset;

  const adjustedPublishDate = new Date(referenceDateTime.getTime() + (referenceTimezoneOffset - currentTimezoneOffset) * 60 * 1000);
  return Math.abs(currentDate.getTime() - adjustedPublishDate.getTime());
};

export const getTimeToOrFrom = (referenceDate: Date): string => {
  const differenceInSeconds = Math.floor((new Date().getTime() - referenceDate.getTime()) / NUM_MILLISECONDS_PER_SECOND);

  if (differenceInSeconds < 0) {
    return getTimeToDate(referenceDate);
  }

  return getTimeFromNow(referenceDate);
};

/**
 * checks the UTC/UNIX date against the current time and returns an internationalized string based on how much time has passed.
 * Examples: Just now, 1 minute ago, 1 hour ago, etc
 * @param {Date} oldDate
 * @return {string}
 */
export const getTimeFromNow = (oldDate: Date): string => {
  const {intervalInMinutes, intervalInHours, intervalInDays, intervalInWeeks, intervalInMonths, intervalInYears} = getTimeDifferenceFromNow(oldDate);

  if (intervalInYears === 1) {
    return window.i18next.t('pmwjs_1_year_ago');
  }
  if (intervalInYears > 1) {
    return window.i18next.t('pmwjs_x_years_ago', {years: intervalInYears});
  }

  if (intervalInMonths === 1) {
    return window.i18next.t('pmwjs_1_month_ago');
  }
  if (intervalInMonths > 1) {
    return window.i18next.t('pmwjs_x_months_ago', {months: intervalInMonths});
  }

  if (intervalInWeeks === 1) {
    return window.i18next.t('pmwjs_1_week_ago');
  }
  if (intervalInWeeks > 1) {
    return window.i18next.t('pmwjs_x_weeks_ago', {weeks: intervalInWeeks});
  }

  if (intervalInDays === 1) {
    return window.i18next.t('pmwjs_1_day_ago');
  }
  if (intervalInDays > 1) {
    return window.i18next.t('pmwjs_x_days_ago', {days: intervalInDays});
  }

  if (intervalInHours === 1) {
    return window.i18next.t('pmwjs_1_hour_ago');
  }
  if (intervalInHours > 1) {
    return window.i18next.t('pmwjs_x_hours_ago', {hours: intervalInHours});
  }

  if (intervalInMinutes === 1) {
    return window.i18next.t('pmwjs_1_minute_ago');
  }
  if (intervalInMinutes > 1) {
    return window.i18next.t('pmwjs_x_minutes_ago', {minutes: intervalInMinutes});
  }

  return window.i18next.t('pmwjs_just_now');
};

export const getTimeFromNowShort = (oldDate: Date): string => {
  const {intervalInMinutes, intervalInHours, intervalInDays, intervalInWeeks, intervalInMonths, intervalInYears} = getTimeDifferenceFromNow(oldDate);

  if (intervalInYears >= 1) {
    return window.i18next.t('pmwjs_x_years_ago_short', {years: intervalInYears});
  }

  if (intervalInMonths >= 1) {
    return window.i18next.t('pmwjs_x_months_ago_short');
  }

  if (intervalInWeeks >= 1) {
    return window.i18next.t('pmwjs_x_weeks_ago_short', {weeks: intervalInWeeks});
  }

  if (intervalInDays >= 1) {
    return window.i18next.t('pmwjs_x_days_ago_short', {days: intervalInDays});
  }

  if (intervalInHours >= 1) {
    return window.i18next.t('pmwjs_x_hours_ago_short', {hours: intervalInHours});
  }

  if (intervalInMinutes >= 1) {
    return window.i18next.t('pmwjs_x_minutes_ago_short', {minutes: intervalInMinutes});
  }

  return window.i18next.t('pmwjs_just_now');
};

export const getTimeToDate = (futureDate: Date): string => {
  const differenceInSeconds = Math.abs(Math.floor((new Date().getTime() - futureDate.getTime()) / NUM_MILLISECONDS_PER_SECOND));
  const {intervalInMinutes, intervalInHours, intervalInDays, intervalInWeeks, intervalInMonths, intervalInYears} = getTimeDifferenceIntervals(differenceInSeconds);

  if (intervalInYears === 1) {
    return window.i18next.t('pmwjs_in_a_year');
  }
  if (intervalInYears > 1) {
    return window.i18next.t('pmwjs_in_x_years', {years: intervalInYears});
  }

  if (intervalInMonths === 1) {
    return window.i18next.t('pmwjs_in_a_month');
  }
  if (intervalInMonths > 1) {
    return window.i18next.t('pmwjs_in_x_months', {months: intervalInMonths});
  }

  if (intervalInWeeks === 1) {
    return window.i18next.t('pmwjs_in_a_week');
  }
  if (intervalInWeeks > 1) {
    return window.i18next.t('pmwjs_in_x_weeks', {weeks: intervalInWeeks});
  }

  if (intervalInDays === 1) {
    return window.i18next.t('pmwjs_in_a_day');
  }
  if (intervalInDays > 1) {
    return window.i18next.t('pmwjs_in_x_days', {days: intervalInDays});
  }

  if (intervalInHours === 1) {
    return window.i18next.t('pmwjs_in_an_hour');
  }
  if (intervalInHours > 1) {
    return window.i18next.t('pmwjs_in_x_hours', {hours: intervalInHours});
  }

  if (intervalInMinutes === 1) {
    return window.i18next.t('pmwjs_in_a_minute');
  }
  if (intervalInMinutes > 1) {
    return window.i18next.t('pmwjs_in_x_minutes', {minutes: intervalInMinutes});
  }

  return window.i18next.t('pmwjs_just_now');
};

export const parseSecondsToMMSS = (
  time: undefined | number
): {
  minutes: string;
  seconds: string;
} => {
  if (!time) {
    return {
      minutes: '00',
      seconds: '00',
    };
  }
  let seconds = String(Math.round(time) % 60);

  if (seconds.length === 1) {
    seconds = `0${seconds}`;
  }

  let minutes = String(Math.floor(Math.round(time) / 60));
  if (minutes.length === 1) {
    minutes = `0${minutes}`;
  }

  return {
    minutes,
    seconds,
  };
};

export const convertSecondsToMMSSFormat = (time: undefined | number): string => {
  const {minutes, seconds} = parseSecondsToMMSS(time);

  return `${minutes}:${seconds}`;
};

export const convertSecondsToMinutesSecondsFormat = (time: undefined | number): string => {
  const {minutes, seconds} = parseSecondsToMMSS(time);

  return minutes === '0' ? `${seconds} sec` : `${minutes} min ${seconds} sec`;
};

export const formatTimeToDisplayFormat = (timeInSeconds: number): string => {
  if (timeInSeconds < 60) {
    return `${timeInSeconds.toFixed(1)}s`;
  }

  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;

  return `${minutes}m ${seconds.toFixed(1)}s`;
};

export const getWeekdayMonthDateFormatDate = (date: Date): string => {
  return new Intl.DateTimeFormat('en-US', {weekday: 'long', day: 'numeric', month: 'short'}).format(date);
};

function getDailyDatesFromRecursivePattern(start: Date, end: Date): string[] {
  const dates: string[] = [];
  const currentDate = new Date(start);

  while (currentDate <= end) {
    dates.push(currentDate.toISOString().split('T')[0]); // Format to 'YYYY-MM-DD'
    currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
  }

  return dates;
}

export const doDatesHaveSameMonth = (date1: Date, date2: Date): boolean => {
  return date1.getMonth() === date2.getMonth();
};

export const getTimeDifferenceIntervals = (differenceInSeconds: number): TimeIntervals => {
  const intervalInYears = Math.floor(differenceInSeconds / NUM_SECONDS_PER_YEAR);
  const intervalInMonths = Math.floor(differenceInSeconds / NUM_SECONDS_PER_MONTH);
  const intervalInWeeks = Math.floor(differenceInSeconds / NUM_SECONDS_PER_WEEK);
  const intervalInDays = Math.floor(differenceInSeconds / NUM_SECONDS_PER_DAY);
  const intervalInHours = Math.floor(differenceInSeconds / NUM_SECONDS_PER_HOUR);
  const intervalInMinutes = Math.floor(differenceInSeconds / NUM_SECONDS_PER_MINUTE);

  return {
    intervalInMinutes,
    intervalInHours,
    intervalInDays,
    intervalInWeeks,
    intervalInMonths,
    intervalInYears,
  };
};

export const getTimeDifferenceFromNow = (date: Date): TimeDifferenceFromNow => {
  const differenceInSeconds = Math.floor((new Date().getTime() - date.getTime()) / NUM_MILLISECONDS_PER_SECOND);

  return {
    differenceInSeconds,
    ...getTimeDifferenceIntervals(differenceInSeconds),
  };
};

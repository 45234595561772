import type {EventsFilters, EventsListLazyData, EventsReduxState, EventTimeFilterMap, EventVO} from '@Components/events/events.types';
import {EventSortBy, EventStatus, EventsViewType, EventTimeFilter} from '@Components/events/events.types';
import {appendFontStyleSheetToDOM, getFontStyleSheetPath} from '@Libraries/font-library';
import {getFontFamilyStyleString} from '@Utils/font.util';
import {generateIncrementalShadedColors} from '@Utils/color.util';
import type {ContactDetailsFormData, PageDetailsFormData} from '@Components/mystuff-events-home/mystuff-events-home.types';
import {EventsHomeIframePreviewHelper} from '@Components/mystuff-events-home/events-home-iframe-preview.class';
import {getDateFromUnixTimestamp, getUnixTimestamp, hasDatedPassed, isBetweenDates, isFutureDate} from '@Utils/date.util';
import {LoadingStates} from '@Utils/loading.util';
import {getUniqueString} from '@Utils/string.util';
import {openEventPreviewModal} from '@Modals/event-preview-modal/event-preview-modal.library';
import type {RecurringEvent} from '@Components/event-wizard/recurring-event-selector/recurring-event-selector.types';
import {RecurringType} from '@Components/event-wizard/recurring-event-selector/recurring-event-selector.types';
import type {Weekdays} from '@Components/event-wizard/week-day-selectors/week-day-selectors.types';
import {ALL_WEEKDAYS} from '@Components/event-wizard/week-day-selectors/week-day-selectors.types';
import {translatedWeekdays} from '@Components/event-wizard/event-wizard-date-time-summary/event-wizard-date-time-summary.types';
import {initSocialMediaProfileLinks} from '@Components/social-media-profile-links/social-media-profile-links-library';
import {getUserId} from '@Libraries/user.library';

export const EVENTS_LAZY_BATCH_SIZE = 3;

export const getURLToDuplicateEvent = (event: EventVO): string => {
  return window.PMW.util.site_url(`event/wizard/${event.hashedID}?is_duplicate=1`);
};

export const getEventPageURL = (event: EventVO): string => {
  return window.PMW.util.site_url(`e/${event.hashedID}`);
};

export const getEventEditURL = (event: EventVO): string => {
  return window.PMW.util.site_url(`event/wizard/${event.hashedID}`);
};

export const isCurrentUserEventCreator = (event: EventVO): boolean => {
  const currentUserId = getUserId();

  if (!currentUserId) {
    return false;
  }

  return isUserEventCreator(event, currentUserId);
};

export const isUserEventCreator = (event: EventVO, userId: number): boolean => {
  return event.creatorId === userId;
};

export const getEventsLazyDataBasedOnAppliedFilters = (state: EventsReduxState, eventsViewType: EventsViewType, eventFilters: EventsFilters): EventsListLazyData => {
  if (!isMyEventsViewType(eventsViewType) || areEventFiltersInDefaultState(eventFilters)) {
    return state.eventsListDefaultLazyData;
  }
  return state.eventsListFilteredLazyData;
};

export const filterEventsByStatusAndSearch = (events: EventVO[], filters: EventsFilters): EventVO[] => {
  return events.filter((userEvent) => {
    return isEventIncludedInSearch(userEvent, filters.searchTerm) && filters.timeFilter[getEventTimeBasedStatus(userEvent)];
  });
};

export const filterEventsBySearch = (events: EventVO[], searchTerm: string): EventVO[] => {
  return events.filter((userEvent) => {
    return isEventIncludedInSearch(userEvent, searchTerm);
  });
};

export const sortEvents = (events: EventVO[], sortEventsBy: EventSortBy): EventVO[] => {
  if (sortEventsBy === EventSortBy.EVENT_START_DATE) {
    return events.sort((event1, event2) => {
      return sortEventsByDates(event1.time?.date_start ?? '', event2.time?.date_start ?? '');
    });
  }

  return events.sort((event1, event2) => {
    return sortEventsByDates(event1.lastModified, event2.lastModified);
  });
};

export const filterEvents = (events: EventVO[], filters: EventsFilters): EventVO[] => {
  return sortEvents(filterEventsByStatusAndSearch(events, filters), filters.sortType);
};

export const openUserEventPreviewDialog = (event: EventVO): void => {
  openEventPreviewModal({
    iframeTitle: event.title,
    iframeURL: getEventPageURL(event),
    showFloatingHeader: true,
    showFloatingScreenOptions: false,
    eventId: event.hashedID,
  });
};

export const openOnlineEventLink = (event: EventVO): void => {
  if (!event.venueOnline?.link || event.isPast) {
    return;
  }

  window.open(event.venueOnline.link, '_blank');
};

export const isOngoingEvent = (event: EventVO): boolean => {
  const eventStartDate = getDateFromUnixTimestamp(event.startDateTimestamp);
  const eventEndDate = getDateFromUnixTimestamp(event.endDateTimestamp);
  const currentDate = new Date();
  return isBetweenDates(currentDate, eventStartDate, eventEndDate);
};

export const isPastEvent = (event: EventVO): boolean => {
  return hasDatedPassed(getDateFromUnixTimestamp(event.endDateTimestamp));
};

export const isUpcomingEvent = (event: EventVO): boolean => {
  return isFutureDate(getDateFromUnixTimestamp(event.startDateTimestamp));
};

export const loadFontFamilyInIframe = (iframeDocument: Document, fontFamily: string): void => {
  const elements = iframeDocument.querySelectorAll('.js-custom-font-family-text');

  elements.forEach((el) => {
    return el.classList.add('-loading');
  });

  appendFontStyleSheetToDOM(fontFamily, getFontStyleSheetPath(fontFamily), iframeDocument, () => {
    void iframeDocument.fonts.load(`16px ${fontFamily}`).then(() => {
      // Apply the font family and remove the '-loading' class for all matching elements
      elements.forEach((el) => {
        (el as HTMLHeadingElement | HTMLSpanElement).style.fontFamily = getFontFamilyStyleString(fontFamily);
        el.classList.remove('-loading');
      });
    });
  });
};

export const applyStylesInEventsHomeIFrame = (pageDetailsForm: PageDetailsFormData, contactDetailsForm: ContactDetailsFormData, iframeClass: string): void => {
  const iframePreviewHelper = new EventsHomeIframePreviewHelper(iframeClass);
  iframePreviewHelper.setEventsHomeTitle(pageDetailsForm.title);
  iframePreviewHelper.setEventsHomeDescription(pageDetailsForm.description);
  iframePreviewHelper.toggleWatermark(pageDetailsForm.doRemoveWatermark);
  iframePreviewHelper.togglePastEvents(pageDetailsForm.doShowPastEvents);
  iframePreviewHelper.applyColorInIframe(pageDetailsForm.color, generateIncrementalShadedColors(pageDetailsForm.color)[1]);
  iframePreviewHelper.applyFontFamilyToHeading(pageDetailsForm.fontFamily);
  iframePreviewHelper.setEmailInIframe(contactDetailsForm.email);
  iframePreviewHelper.setPhoneNumberInIframe(contactDetailsForm.phoneNumber);
  iframePreviewHelper.setAllSocialLinksInIframe(contactDetailsForm.socialMediaProfilesInput);
  iframePreviewHelper.setImagesInIframe(pageDetailsForm.imageURL, pageDetailsForm.coverPhotoURL);
};

export const getDefaultLazyData = (loadingState: LoadingStates = LoadingStates.NOT_LOADED): EventsListLazyData => {
  return {
    ids: [],
    loadingState,
    loadMore: true,
  };
};

export const areEventFiltersInDefaultState = (filters: EventsFilters): boolean => {
  return filters.sortType === EventSortBy.EVENT_START_DATE && areAllEventStatusesSelectedInFilter(filters.timeFilter);
};

export const isMyEventsViewType = (eventsViewType: EventsViewType): boolean => {
  return eventsViewType === EventsViewType.DEFAULT;
};

export const isSharedWithMeEventsViewType = (eventsViewType: EventsViewType): boolean => {
  return eventsViewType === EventsViewType.SHARED_WITH_ME;
};

export const areAllEventStatusesSelectedInFilter = (statusFilter: EventTimeFilterMap): boolean => {
  return (
    Object.values(statusFilter).find((val) => {
      return !val;
    }) === undefined
  );
};

export const getFirstWeekdayForWeeklyRecurringEvent = (recurringPattern?: RecurringEvent): Weekdays | null => {
  if (!recurringPattern || recurringPattern.recurringType !== RecurringType.WEEKLY) {
    return null;
  }

  const weekdaysArraySorted = [...recurringPattern.recurringWeeks];
  weekdaysArraySorted.sort((a, b) => {
    return ALL_WEEKDAYS.indexOf(a) - ALL_WEEKDAYS.indexOf(b);
  });
  return weekdaysArraySorted[0];
};

export const getFirstWeekdayForMonthlyRecurringEvent = (recurringPattern: RecurringEvent | undefined): Weekdays | null => {
  if (!recurringPattern || recurringPattern.recurringType !== RecurringType.MONTHLY || !recurringPattern.recurringMonthly) {
    return null;
  }

  return recurringPattern.recurringMonthly.weekday;
};

export const getRecurringEventDateText = (recurringEventPattern?: RecurringEvent): string => {
  if (!recurringEventPattern) {
    return '';
  }

  let title = window.i18next.t('pmwjs_daily');
  const recurringWeeklyWeekdayFirst = getFirstWeekdayForWeeklyRecurringEvent(recurringEventPattern);
  const recurringMonthlyWeekday = getFirstWeekdayForMonthlyRecurringEvent(recurringEventPattern);

  if (recurringWeeklyWeekdayFirst) {
    const weekdayInd = ALL_WEEKDAYS.indexOf(recurringWeeklyWeekdayFirst);
    title = window.i18next.t('pmwjs_every_weekday', {weekday: translatedWeekdays[weekdayInd]});
  } else if (recurringMonthlyWeekday) {
    const weekdayInd = ALL_WEEKDAYS.indexOf(recurringMonthlyWeekday);
    title = window.i18next.t('pmwjs_every_x_weekday', {number: recurringEventPattern.recurringMonthly.weekdayNum, weekday: translatedWeekdays[weekdayInd]});
  }

  return title;
};

export const getDummyEventItem = (): EventVO => {
  return {
    hashedID: getUniqueString(),
    title: '',
    description: '',
    endDateTime: '',
    startDateTime: '',
    startDateTimestamp: getUnixTimestamp(new Date()),
    endDateTimestamp: getUnixTimestamp(new Date()),
    numRegistrations: 0,
    showCountdown: false,
    isRemoveWatermark: false,
    isShared: false,
    isPast: false,
    tzcode: '',
    registrationFields: {
      email: false,
      name: false,
      phone: false,
      registerBtnText: '',
    },
    organiser: {
      name: '',
      email: '',
      profilePic: '',
      isProfilePicturePrefilled: false,
      socialMediaLinks: initSocialMediaProfileLinks(),
    },
    status: EventStatus.DRAFT,
    type: {
      hashedID: '',
      name: '',
    },
    lastModified: '',
    style: {
      fontFamily: '',
      colors: {
        primary: '',
        light: '',
        lighter: '',
        dark: '',
      },
    },
    allowRegistrations: false,
    creatorId: -1,
  };
};

const sortEventsByDates = (date1: string, date2: string): number => {
  return new Date(date2).getTime() - new Date(date1).getTime();
};

const isEventIncludedInSearch = (userEvent: EventVO, searchTerm: string): boolean => {
  return userEvent.title.toLowerCase().includes(searchTerm.toLowerCase());
};

/**
 *
 */
const getEventTimeBasedStatus = (userEvent: EventVO): EventTimeFilter => {
  if (isOngoingEvent(userEvent)) {
    return EventTimeFilter.LIVE;
  }

  if (isUpcomingEvent(userEvent)) {
    return EventTimeFilter.UPCOMING;
  }

  if (isPastEvent(userEvent)) {
    return EventTimeFilter.PAST;
  }

  return EventTimeFilter.PAST;
};

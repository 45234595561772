export interface EventWizardDateTimeSummaryProps {
  toggleExpandedState: () => void;
  isExpanded: boolean;
}

export const translatedWeekdays = [
  window.i18next.t('pmwjs_calendar_monday'),
  window.i18next.t('pmwjs_calendar_tuesday'),
  window.i18next.t('pmwjs_calendar_wednesday'),
  window.i18next.t('pmwjs_calendar_thursday'),
  window.i18next.t('pmwjs_calendar_friday'),
  window.i18next.t('pmwjs_calendar_saturday'),
  window.i18next.t('pmwjs_calendar_sunday'),
];
